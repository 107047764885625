import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { $, add } from 'moneysafe';
import * as FS from '@fullstory/browser';
import { Modal } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import { ROUTES } from 'config/constants';

import { addItemsToCart } from 'api/cart';
import { trackKlaviyoEvent } from 'api/tracking';
import { getAuthedProfile } from 'api/user';
import { loginModal } from 'redux/reducers/auth';
import { addProductToCart, clearCart, notifyHeaderOnCartUpdate } from 'redux/reducers/cart';

import EligiblePopUp from 'components/EligiblePopUp';
import RentalPeriod from '../RentalPeriod/RentalPeriod';
import ColorOptionsMobileView from '../ColorOptionsMobileView/ColorOptionsMobileView';
import StorageOptionsMobieView from '../StorageOptionsMobieView/StorageOptionsMobieView';
import Button from 'containers/App/components/Button/Button';
import Cart from '../Cart/Cart';
import { notifType, notify } from 'utils/notifSender';
import FormMessage from '../FormMessage';
import TradeIn from '../TradeIn/TradeIn';
import CommitmentAndInsideBox from '../CommitmentAndInsideBox/CommitmentAndInsideBox';

import ShoppingCartWhite from 'assets/images/general/cart-outline-white.svg';

import './BeforeBuy.scss';
import style from './BeforeBuy.module.scss';

BeforeBuy.propTypes = {
    title: PropTypes.string.isRequired,
    priceDetails: PropTypes.string.isRequired,
    loginModal: PropTypes.func.isRequired,
    currency: PropTypes.string.isRequired,
    colors: PropTypes.array.isRequired,
    rentalOptions: PropTypes.array.isRequired,
    token: PropTypes.string.isRequired,
    addProductToCart: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    clearCart: PropTypes.func.isRequired,
    singpassStatus: PropTypes.string,
    attribute: PropTypes.array,
    selectedRentalPeriod: PropTypes.object,
    setSelectedRentalPeriod: PropTypes.func,
    selectedColor: PropTypes.object,
    setSelectedColor: PropTypes.func,
    selectedStorage: PropTypes.object,
    setSelectedStorage: PropTypes.func,
    images: PropTypes.array,
    colorsGallery: PropTypes.array,
    isAuthed: PropTypes.bool
};

function BeforeBuy({
    title,
    loginModal,
    currency,
    colors,
    token,
    rentalOptions,
    addProductToCart,
    product,
    singpassStatus,
    attribute,
    selectedRentalPeriod,
    setSelectedRentalPeriod,
    selectedColor,
    setSelectedColor,
    selectedStorage,
    setSelectedStorage,
    images,
    colorsGallery,
    isAuthed
}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [openCart, setOpenCart] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [flag, setFlag] = useState(false);
    const [eligible, setEligible] = useState(false);
    const { profile } = useSelector((state) => ({ profile: state.auth.profile }));
    const isMobile = window.matchMedia('(max-width: 767px)').matches;
    const [isTradeInApplied, setIsTradeInApplied] = useState(false);
    const { meta } = useSelector(state => ({ meta: state.cart.overview }));
    const { cartItems } = useSelector(state => ({ cartItems: state.cart.fetchedProducts }));

    useEffect(() => {
        if (selectedColor !== null && selectedRentalPeriod !== null) {
            trackKlaviyoEvent('product-view', {
                title: title,
                product: [product],
                images: images && images[0] && images[0]?.images,
                price: getPrice(),
                selectedRentalPeriod,
                selectedColor,
                selectedStorage,
                email: profile.email,
                user_id: profile.id
            });
        }
    }, [selectedRentalPeriod, selectedColor, selectedStorage]);

    const reFetchCart = () => setFlag(!flag);

    const getPrice = () => {
        let price = $(selectedRentalPeriod.price);

        if (selectedStorage && selectedStorage.item.price_impact) {
            const find = selectedStorage.item.price_impacts.find(e => e.rental_period_id === selectedRentalPeriod.id);

            if (find) {
                price = add($(price), $(find.price_impacts));
            }
        }

        return price.toString();
    };

    const handleRental = i => { setSelectedRentalPeriod(i); };

    const handleAddToCart = async () => {
        if (selectedRentalPeriod.id === -1) {
            notify('Please select a rental option.', notifType.DANGER);

            return true;
        }

        if (colors.length !== 0 && !isInStock(selectedColor)) {
            notify('Selected variant is out of stock', notifType.DANGER);

            return true;
        }

        if (colors.length !== 0 && !selectedColor) {
            notify('Please select a color.', notifType.DANGER);

            return true;
        }

        if ((attribute && attribute.length !== 0 && Object.prototype.hasOwnProperty.call(attribute, 'attribute_storage'))) {
            if (!selectedStorage) {
                notify('Please select a storage option.', notifType.DANGER);

                return true;
            }
        }

        if (token === '') {
            loginModal(true);

            addProductToCart({
                ...product,
                images,
                selectedRentalPeriod,
                selectedColor,
                selectedStorage
            });

            FS.event('Product Added To Cart', {
                product_id: product.id.toString(),
                product_name: product.name,
                product_attribute_value_id: selectedStorage ? selectedStorage.value
                    : (
                        product.attributes.specifications.values[0].value ||
                        product.attributes.inside_box.values[0].value
                    ),
                quantity: '1',
                rental_period: selectedRentalPeriod.duration_time,
                color_id: selectedColor.value
            });
            const baseProduct = { ...product };

            baseProduct.total = getPrice();
            baseProduct.medias = images && images[0] && images[0].images;

            trackKlaviyoEvent('add-cart', {
                email: profile?.email,
                user_id: profile?.id,
                title: title,
                product: product,
                images: images[0].images,
                price: getPrice(profile?.is_student ? 'b2s' : 'b2c'),
                cartItems: cartItems,
                cartMeta: meta,
                selectedRentalPeriod: selectedRentalPeriod,
                selectedColor: selectedColor,
                selectedStorage: selectedStorage
            });
        } else {
            const makeProducts = [];
            const keys = Object.keys(product.attributes);

            if ((product.attributes && keys.length > 0 && keys.includes('storage'))) {
                const storageAttributes = product?.attributes?.storage?.values;

                makeProducts.push(
                    {
                        is_purchase: false,
                        product_attribute_value_id: selectedStorage.value || storageAttributes[0]?.id,
                        quantity: 1,
                        rental_period_id: selectedRentalPeriod.id,
                        rental_duration_time: selectedRentalPeriod.duration_time,
                        product_id: product.id,
                        color_id: selectedColor.value,
                        deal_id: null,
                        flash_deal_product_id: null
                    }
                );
            } else {
                makeProducts.push(
                    {
                        is_purchase: false,
                        product_attribute_value_id: (product.attributes.specifications.values[0].id || product.attributes.inside_box.values[0].id),
                        quantity: 1,
                        rental_period_id: selectedRentalPeriod.id,
                        rental_duration_time: selectedRentalPeriod.duration_time,
                        product_id: product.id,
                        color_id: selectedColor.value,
                        deal_id: null,
                        flash_deal_product_id: null
                    }
                );
            }

            setLoading(true);

            try {
                await addItemsToCart(makeProducts);
                dispatch(notifyHeaderOnCartUpdate(true));

                trackKlaviyoEvent('add-cart', {
                    email: profile?.email,
                    user_id: profile?.id,
                    title: title,
                    product: product,
                    images: images[0].images,
                    price: getPrice(profile?.is_student ? 'b2s' : 'b2c'),
                    cartItems: [product],
                    cartMeta: meta,
                    selectedRentalPeriod: selectedRentalPeriod,
                    selectedColor: selectedColor,
                    selectedStorage: selectedStorage
                });

                notify('Product added to cart.', notifType.SUCCESS);

                setOpenCart(true);

                if ((product.attributes && keys.length > 0 && keys.includes('storage'))) {
                    const storageAttributes = product?.attributes?.storage?.values;

                    FS.event('Product Added To Cart', {
                        product_id: product.id.toString(),
                        product_name: product.name,
                        product_attribute_value_id: selectedStorage.value || storageAttributes[0]?.id,
                        quantity: '1',
                        rental_period: selectedRentalPeriod.duration_time,
                        color_id: selectedColor.value
                    });
                } else {
                    FS.event('Product Added To Cart', {
                        product_id: product.id.toString(),
                        product_name: product.name,
                        product_attribute_value_id: (product.attributes.specifications.values[0].id || product.attributes.inside_box.values[0].id),
                        quantity: '1',
                        rental_period: selectedRentalPeriod.duration_time,
                        color_id: selectedColor.value
                    });
                }
            } catch (error) {
                Sentry.captureException(error);
            }

            setLoading(false);
        }
    };

    const submitCart = async () => {
        // if user is otp_required then should complete the Singpass info
        if (!singpassStatus || singpassStatus !== 'verified') {
            notify('Complete your profile first.', notifType.DANGER);

            history.push(ROUTES.MYINFORMATION);

            return;
        }

        // if user is verified can proced the order
        if (singpassStatus === 'verified') {
            history.push(ROUTES.CART);
        }
    };

    const handleColor = e => setSelectedColor(e);

    const isInStock = (selectedColor) => {
        /* eslint-disable camelcase */
        const internalAsset = product?.internal_asset_codes?.find(({ product_attribute_value_id, color_id }) => (
            (product_attribute_value_id ? product_attribute_value_id === selectedStorage?.value : true) &&
            color_id === selectedColor?.value
        ));

        return internalAsset?.in_stock === 1;
    };

    const handleStorage = e => setSelectedStorage(e);

    const [authProfile, setAuthProfile] = useState({});

    const verified = authProfile?.data?.singpass_status === 'verified';

    const isEligible = () => {
        if (isAuthed && verified) {
            return selectedStorage ? selectedStorage.item.eligible : product.eligible;
        }

        return true;
    };

    useEffect(() => {
        if (isAuthed) {
            getAuthedProfile().then((res) => {
                setAuthProfile(res);
            });
        }
    }, [isAuthed]);

    return (<div className={style.BeforeBuy}>
        <EligiblePopUp isOpen={eligible} onToggle={() => setEligible(false)} />
        {'eligible' in product && !isEligible() &&
            <div className={style.BeforeBuy__flex}>
                <div className={style.BeforeBuy__noteligible} onClick={() => setEligible(true)}>
                    {/* <Noteligible className={style.BeforeBuy__noteligibleIcon} /> */}
                    Not Eligible
                </div>
            </div>
        }
        <h1 className={style.title}>{title}</h1>
        <p className={`${style.price} ${style.price__retail_value}`}>RRP: <s>{currency} {product?.retail_value?.toLocaleString('en-US')}</s></p>

        {(attribute && attribute.length > 0 && attribute.some(e => e.item && e.item.key === 'attribute_storage')) &&
            <div className='storage__section-section '>
                <StorageOptionsMobieView
                    options={attribute}
                    selectedStorage={selectedStorage}
                    onClick={handleStorage} />
            </div>}

        <div className='colors__section-section'>
            <ColorOptionsMobileView
                options={colors}
                selectedColor={selectedColor}
                colorsGallery={colorsGallery}
                onClick={handleColor} />
        </div>

        {!isMobile && rentalOptions.length !== 0 &&
            <div className='rental__period-section'>
                <RentalPeriod
                    rentalOptions={rentalOptions}
                    selected={selectedRentalPeriod}
                    isSelected={false}
                    onClick={handleRental} />
            </div>}

        {isMobile && rentalOptions.length !== 0 &&
            <div className='rental__period-section'>
                <RentalPeriod
                    rentalOptions={rentalOptions}
                    selected={selectedRentalPeriod}
                    isSelected={false}
                    onClick={handleRental} />
            </div>}

        {/* {isMobile &&
            <div className='rental__period-section'>
                <Freebies />
            </div>} */}

        {product?.category?.name === 'Smartphones' &&
            <div className='trade-ins'>
                <TradeIn setIsTradeInApplied={setIsTradeInApplied} />
            </div>}

        <CommitmentAndInsideBox insideBoxItems={product?.attributes?.inside_box?.values} />

        {(rentalOptions.length !== 0) &&
            <div className='desktop-add-to-cart-section-container container-fluid'>
                <div className='desktop-add-to-cart-section'>
                    <div className='desktop-add-to-cart-section__content d-flex'>
                        <div className='title-container'>
                            <p className='title d-flex justify-content-between align-items-center'>
                                {_.truncate(title, { length: 50, omission: '...' })}&nbsp;
                                <span className='desc ml-3'>{`${selectedStorage?.label ? selectedStorage?.label + ' | ' : ' '}`} {selectedColor?.text}</span>
                            </p>
                            <p className='duration justify-content-between mb-0'>
                                <span className='duration__content mr-3'>For&nbsp;<span className='duration__value'>{selectedRentalPeriod?.duration_time} Month{selectedRentalPeriod.duration_time > 1 && 's'}</span></span>
                                <span className={`${isTradeInApplied ? 'duration__content' : ''} duration__value`}>FREE Damage Cover</span>
                                {isTradeInApplied && <span className='trade-in-applied trade-in-applied__mobile'>Trade-In Applied</span>}
                            </p>
                        </div>
                        {isTradeInApplied && <span className='trade-in-applied trade-in-applied__desktop'>Trade-In Applied</span>}
                    </div>
                    <div className='desktop-add-to-cart-section__button-container'>
                        <Button
                            className='desktop-add-to-cart-section__button-container__button'
                            loading={loading}
                            onClick={handleAddToCart}
                            disabled={!isInStock(selectedColor)}
                            title={<span className='d-flex align-items-center'>
                                <span className='price'>{currency} {getPrice()}</span><span className='month'>&nbsp;/mo</span>
                                <img className='desktop-add-to-cart-section__button-container__cart-icon'
                                    src={ShoppingCartWhite} alt='Add to cart' />
                            </span>} />
                    </div>
                </div>
            </div>}

        <Cart
            cartMeta={meta}
            reFetchCart={reFetchCart}
            cartItems={cartItems}
            loading={loading}
            submitCart={submitCart}
            isOpen={openCart}
            toggle={() => setOpenCart(false)} />

        <Modal
            onClosed={() => setOpenModal(false)}
            className={style.modal}
            centered={true}
            isOpen={openModal}>
            <FormMessage />
        </Modal>
    </div>);
}

function mapDispatchToProps(dispatch) {
    return {
        loginModal: openLogin => dispatch(loginModal(openLogin)),
        addProductToCart: product => dispatch(addProductToCart(product)),
        clearCart: () => dispatch(clearCart())
    };
}

export default connect(null, mapDispatchToProps)(BeforeBuy);
